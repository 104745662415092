<template>
	<el-container class="uu-page">
		<el-header class="page-head" height="49">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'batchConsign' }">{{$t('seller.breadcrumb.shipping')}}</el-breadcrumb-item>
				<el-breadcrumb-item>{{$t('seller.breadcrumb.logisticsDetail')}}</el-breadcrumb-item>
			</el-breadcrumb>
		</el-header>
		<el-scrollbar class="uu-box">
			<div class="page-container">
				<div class="uu-message">
					<div class="message-notice">
						<i class="el-icon-warning"></i>
						<div class="message-notice-main">
							<p>{{$t('seller.order.logisTips1')}}</p>
							<p>{{$t('seller.order.logisTips2')}}</p>
						</div>
					</div>
				</div>
				<div class="page-top">
					<div class="top-wrap">
						<p><span class="mr60">{{$t('seller.order.dialogAddress1')+":"}}{{orderInfo.order_sn}}</span><span>{{$t('seller.order.payTime')+":"}}{{orderInfo.pay_time | parseTime()}}</span></p>
						<p>
							<span v-if="visibleAddress">{{$t('seller.order.receivingAddress')+":"}}{{addressInfo.region_name}}{{addressInfo.address?onAddressText(addressInfo.address):''}}，{{addressInfo.consignee?onAddressName(addressInfo.consignee):''}}，{{addressInfo.phone_mob?onAddressMobild(addressInfo.phone_mob):''}}</span>
							<span v-else>{{$t('seller.order.receivingAddress')+":"}}{{addressInfo.region_name}}{{addressInfo.address}}，{{addressInfo.zipcode}}，{{addressInfo.consignee}}，{{addressInfo.phone_mob}}</span>
							<i class="uu-icon-view" :class="visibleAddress?'active':''" @click="handleVisibleAddress"></i>
						</p>
					</div>
					<div class="top-bar">
						<el-popover
							placement="left"
							trigger="hover">
							<div>
								<div><strong>{{$t('seller.order.returnInfo')}}</strong></div>
								<div v-if="deliveryData.store_receiving_info">{{deliveryData.store_receiving_info.region_name}}{{deliveryData.store_receiving_info.address}},
								{{deliveryData.store_receiving_info.zipcode}},
								{{deliveryData.store_receiving_info.shipaddr_name}},
								{{deliveryData.store_receiving_info.phone_mob}}</div>
								<div><strong>{{$t('seller.order.shippingInfo')}}</strong></div>
								<div v-if="deliveryData.store_shipping_info">{{deliveryData.store_shipping_info.region_name}}{{deliveryData.store_shipping_info.address}},
								{{deliveryData.store_shipping_info.zipcode}},
								{{deliveryData.store_shipping_info.shipaddr_name}},
								{{deliveryData.store_shipping_info.phone_mob}}</div>
							</div>
							<a href="javascript:void(0);" slot="reference">{{$t('seller.actions.viewAddress')}}</a>
						</el-popover>
						
					</div>
				</div>
				<template v-if="deliveryList.length>0">
					<el-tabs v-model="deliveryActive" type="card"  @tab-click="handleClickDelivery">
						<el-tab-pane v-for="(item,index) in deliveryList" :key="index" :label="$t('seller.order.logisNo')+item.logistic_code" :name="index.toString()"></el-tab-pane>
					</el-tabs>
					<div class="page-main">
						<div class="page-section">
							<div class="section-title">{{$t('seller.order.packageInfo')}}</div>
							<el-form label-width="100px">
								<el-form-item :label="$t('seller.formItem.logisticsNo')+':'">{{deliveryData.logistic_code}} <a class="page-link" href="javascript:void(0);" @click="handleEditDelivery">{{$t('seller.actions.modifyOrderSn')}}</a></el-form-item>
								<el-form-item :label="$t('seller.formItem.shippingType')+':'">{{deliveryData.delivery_name?deliveryData.delivery_name:$t('seller.order.shopDelivery')}}</el-form-item>
								<el-form-item :label="$t('seller.formItem.shippingTime')+':'">{{orderInfo.ship_time | parseTime()}}</el-form-item>
								<!-- <el-form-item :label="$t('seller.formItem.performanceNo')+':'">LP00025807025247</el-form-item> -->
								<el-form-item :label="$t('seller.formItem.packageGoods')+':'">
									<template v-if="deliveryData.goods_info">
										<div class="goods-item" v-for="(item,index) in deliveryData.goods_info" :key="index">
											<img class="pic" :src="item.goods_image" alt="">
											<div class="item-block">
												<div class="item-main">
													<div class="name">{{item.goods_name}}</div>
													<div class="text">
														<el-tag size="mini">{{item.rec_id}}</el-tag>
													</div>
												</div>
											</div>
											<div class="item-num">x {{item.quantity}}</div>
										</div>
									</template>
									<template v-else>
										<div class="goods-item" v-for="(item,index) in orderInfo.order_goods" :key="index">
											<img class="pic" :src="item.goods_image" alt="">
											<div class="item-block">
												<div class="item-main">
													<div class="name">{{item.goods_name}}</div>
													<div class="text">
														<el-tag size="mini">{{item.rec_id}}</el-tag>
													</div>
												</div>
											</div>
											<div class="item-num">x {{item.quantity}}</div>
										</div>
									</template>
								</el-form-item>
							</el-form>
						</div>
						<div class="page-section">
							<div class="section-title">{{$t('seller.order.logisStatus')}}</div>
							<div class="page-logis">
								<template v-if="deliveryData.trace">
									<el-timeline :reverse="true">
										<el-timeline-item
											v-for="(trace, index3) in deliveryData.trace"
											:key="index3"
											:timestamp="trace.AcceptTime">
											{{trace.AcceptStation}}
										</el-timeline-item>
									</el-timeline>
								</template>
								<el-empty :description="$t('seller.order.noLogisStatus')" v-else></el-empty>
							</div>
						</div>
					</div>
				</template>
				<div class="page-main" v-else>
					<div class="page-section">
						<div class="section-title">{{$t('seller.order.packageInfo')}}</div>
						<el-form label-width="100px">
							<el-form-item :label="$t('seller.formItem.logisticsNo')+':'">{{$t('seller.order.unLogisticsWaybillNo')}}</el-form-item>
							<el-form-item :label="$t('seller.formItem.shippingType')+':'">{{$t('seller.order.unLogistics')}}</el-form-item>
							<el-form-item :label="$t('seller.formItem.shippingTime')+':'">{{orderInfo.ship_time | parseTime()}}</el-form-item>
							<!-- <el-form-item :label="$t('seller.formItem.performanceNo')+':'">LP00025807025247</el-form-item> -->
							<el-form-item :label="$t('seller.formItem.packageGoods')+':'">
								<div class="goods-item" v-for="(item,index) in orderInfo.order_goods" :key="index">
									<img class="pic" :src="item.goods_image" @error="imageLoadError" alt="">
									<div class="item-block">
										<div class="item-main">
											<div class="name">{{item.goods_name}}</div>
											<div class="text">
												<el-tag size="mini">{{item.rec_id}}</el-tag>
											</div>
										</div>
									</div>
									<div class="item-num">x {{item.quantity}}</div>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<div class="page-section">
						<div class="section-title">{{$t('seller.order.logisStatus')}}</div>
						<div class="page-logis">
							<el-empty :description="$t('seller.order.noLogisStatus')"></el-empty>
						</div>
					</div>
				</div>
				<el-dialog
					:title="$t('seller.dialogTitle.modifyWaybillNo')"
					:visible.sync="dialogVisibleDelivery"
					width="460px"
					:before-close="handleCloseDelivery">
					<el-form label-width="80px">
						<el-form-item :label="$t('seller.formItem.logisticsNo')">
							<el-input v-model="logisticsCode" :placeholder="$t('seller.placeholder.logisticsNo')"></el-input>
						</el-form-item>
					</el-form>
					
					<div slot="footer" class="dialog-footer">
						<el-button @click="handleCloseDelivery">{{$t('seller.actions.cancel')}}</el-button>
						<el-button type="primary" @click="handleConfirmDelivery">{{$t('seller.actions.confirm')}}</el-button>
					</div>
				</el-dialog>
			</div>
		</el-scrollbar>
	</el-container>
</template>

<script>
	import { fetchDeliver, modifyWaybillNo } from '@/api/seller/order';
	export default {
		data() {
			return {
				orderInfo: {},
				addressInfo: {},
				visibleAddress: true,
				deliveryList: [],
				deliveryActive: '0',
				deliveryData: {
					store_receiving_info: {},
					store_shipping_info: {},
				},
				logisticsCode: '',
				dialogVisibleDelivery: false,
			}
		},
		created() {
			this.getOrderInfo();
		},
		methods:{
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getOrderInfo(){
				fetchDeliver({order_id: this.$route.params.id}).then(response => {
					this.orderInfo = response.data;
					this.addressInfo = response.data.order_extm;
					this.deliveryList = response.data.delivery_dist || [];
					this.deliveryData = response.data.delivery_dist?response.data.delivery_dist[0]:{store_receiving_info: {},store_shipping_info: {}};
				}).catch(error => {
					this.$router.push({name: 'batchConsign'});
				})
			},
			onAddressText(val){
				return '*****************';
			},
			onAddressName(val){
				if(val){
					return val.substr(0,1)+"**";
				}else{
					return '';
				}
			},
			onAddressMobild(tel){
				return "***********";
			},
			handleVisibleAddress(){
				this.visibleAddress = this.visibleAddress?false:true;
			},
			handleClickDelivery(tab, event){
				this.deliveryData = this.deliveryList[this.deliveryActive]?this.deliveryList[this.deliveryActive]:{store_receiving_info: {},store_shipping_info: {}};
			},
			handleEditDelivery(){
				this.logisticsCode = '';
				this.dialogVisibleDelivery = true;
			},
			handleCloseDelivery(){
				this.dialogVisibleDelivery = false;
			},
			handleConfirmDelivery(){
				if(!this.logisticsCode){
					this.$message.error(this.$t('seller.errorMsg.logisticsNo'));
					return false;
				}
				let param = {
					delivery_dist_id: this.deliveryData.delivery_dist_id,
					logistic_code: this.logisticsCode,
					order_sn: this.orderInfo.order_sn
				}
				modifyWaybillNo(param).then(() => {
					this.$message.success(this.$t('seller.successMsg.modify'));
					this.getOrderInfo();
					this.dialogVisibleDelivery = false;
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-page {
		padding: 0;
		.page-head {
			padding: 16px 20px;
			border-bottom: 1px solid #e6e9ed;
			::v-deep .el-breadcrumb {
				line-height: 16px;
			}
		}
		.uu-box {
			height: calc(100vh - 124px);
			.page-container {
				padding: 20px;
				.uu-message {
					margin-bottom: 20px;
					.message-notice {
						padding: 12px;
						border-radius: 9px;
						line-height: 18px;
						background-color: $--seller-notice-background;
						.el-icon-warning {
							color: $--seller-primary;
							float: left;
							font-size: 18px;
						}
						.message-notice-main {
							width: 100%;
							color: #666666;
							font-size: 12px;
							padding-left: 24px;
							padding-right: 15px;
							box-sizing: border-box;
							a {
								color: $--seller-primary;
							}
						}
					}
				}
				.page-top {
					padding: 20px 0;
					// border-bottom: 1px solid #e6e9ed;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.top-wrap {
						font-size: 14px;
						line-height: 24px;
						.mr60 {
							margin-right: 60px;
						}
						i {
							margin-left: 10px;
						}
						.uu-icon-view {
							width: 20px;
							height: 20px;
							cursor: pointer;
							display: inline-block;
							vertical-align: middle;
							background-image: url('~@/assets/images/seller/order-see.png');
							background-size: auto 100%;
							background-repeat: no-repeat;
							background-position: left;
						}
						.uu-icon-view.active {
							background-position: right;
						}
					}
					.top-bar {
						padding-right: 60px;
						font-size: 14px;
						a {
							color: $--seller-primary;
						}
					}
				}
				.page-main {
					display: flex;
					padding: 20px 30px 0;
					.page-section {
						flex-shrink: 1;
						width: 50%;
						padding: 0 30px;
						border-left: 1px solid #e5e5e5;
						.section-title {
							font-size: 16px;
							font-weight: bolder;
							margin-bottom: 20px;
						}
						.goods-item {
							display: flex;
							align-items: center;
							margin-bottom: 12px;
							.pic {
								width: 60px;
								height: 60px;
								border-radius: 8px;
								object-fit: cover;
								margin-right: 10px;
								border: 1px solid #E5E5E5;
							}
							.item-block {
								flex: 1;
								display: flex;
								align-items: center;
							}
							.item-main {
								flex: 1;
								width: 0;
								display: block;
								line-height: normal;
								.name {
									max-height: 40px;
									line-height: 20px;
									color: $--seller-primary;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
								}
								.text {
									margin-top: 5px;
								}
								::v-deep .el-tag {
									color: #333333;
									border-color: $--seller-tag-color-1;
									background-color: $--seller-tag-color-1;
								}
							}
							.item-num {
								min-width: 30px;
								margin-left: 10px;
							}
						}
					}
					.page-section:first-child {
						border-left: none;
					}
					.page-link {
						color: $--seller-primary;
					}
				}
			}
		}
	}
</style>
